var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('titulo-seccion',[_c('template',{slot:"area-rutas"},[_c('a',{attrs:{"href":"/"}},[_vm._v("Inicio")]),_vm._v(" / "),_c('span',{staticClass:"active"},[_vm._v("Fiscalías")])]),_c('template',{slot:"area-titulo"},[(_vm.item.fiscalia_padre)?_c('h3',{staticClass:"text-center"},[_vm._v(" Asiento Fiscal "+_vm._s(_vm.formTitle)+" ")]):_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.formTitle))])])],2),_c('div',{staticClass:"container mt-4",attrs:{"id":"content-page"}},[_c('b-row',{attrs:{"cols":"12"}},[_c('b-col',{attrs:{"lg":"4"}},[_c('b-card',{staticClass:"card-developer-meetup",attrs:{"no-body":""}},[_c('div',{staticClass:"text-center bg-light-primary rounded-top"},[(_vm.item.foto_fiscalia)?_c('b-img',{staticClass:"img-fluid",attrs:{"src":_vm.item.foto_fiscalia,"alt":_vm.item.nombre}}):_c('b-img',{attrs:{"src":require('@/assets/images/imagen-mapa.png'),"alt":"Mapa ubicaciones","height":"170"}})],1),_c('b-card-body',[_c('div',{staticClass:"meetup-header d-flex align-items-center"},[_c('b-row',[_c('div',{staticClass:"my-auto"},[_c('b-card-title',{staticClass:"mb-25"},[(_vm.item.fiscalia_padre)?_c('h3',[_c('router-link',{attrs:{"to":'/fiscalia/' + _vm.item.fiscalia_padre.url}},[_vm._v(_vm._s(_vm.item.fiscalia_padre.nombre))])],1):_c('h3',[_vm._v(" "+_vm._s(_vm.item.nombre)+" ")])]),_c('b-card-text',{staticClass:"mb-0"},[_c('b-button',{staticClass:"btn-fiscalia",style:({
                        '--background-color-btn':
                          _vm.config.disenio.datos.color_secundario,
                      }),attrs:{"block":"","to":"/fiscalias"}},[_vm._v(" Fiscalías")])],1)],1)])],1)])],1),_c('b-list-group',[_vm._l((_vm.itemsmenu),function(item){return [_c('b-list-group-item',{key:item.id},[_c('router-link',{staticClass:"text-secondary",staticStyle:{"text-decoration":"none"},style:({
                  '--color-secondary': _vm.config.disenio.datos.color_secundario,
                }),attrs:{"to":'/fiscalia/' + item.url,"exact-path":""}},[_vm._v(_vm._s(item.nombre))])],1)]})],2)],1),_c('b-col',{attrs:{"lg":"8"}},[_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.textBusqueda),expression:"textBusqueda"}],staticClass:"form-control",attrs:{"type":"search","placeholder":"Búsqueda por fiscalías"},domProps:{"value":(_vm.textBusqueda)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.buscar.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.textBusqueda=$event.target.value}}}),_c('button',{staticClass:"btn btn-fiscalia",style:({
                '--background-color-btn':
                  _vm.config.disenio.datos.color_secundario,
              }),attrs:{"type":"button"},on:{"click":_vm.buscar}},[_vm._v(" Buscar ")])])]),_vm._l((_vm.itemsBusqueda),function(item){return _c('b-row',{key:item.id,staticClass:"mt-2"},[_c('b-col',[_c('FiscaliaInfo',{attrs:{"fiscaliaData":item}})],1)],1)}),_c('b-row',[_c('b-col',{staticClass:"mt-3",attrs:{"cols":_vm.getCols(_vm.item.foto_fiscal, 4)}},[_c('div',{staticClass:"text-center"},[(_vm.item.foto_fiscal)?_c('b-img-lazy',{staticClass:"mb-1 rounded me-2 mb-2",attrs:{"src":_vm.item.foto_fiscal,"height":"250","width":"250","thumbnail":""}}):_vm._e(),_c('b-card-title',[_c('strong',{staticClass:"text-secondary",style:({
                    '--color-secondary':
                      _vm.config.disenio.datos.color_secundario,
                  })},[_vm._v(_vm._s(_vm.item.nombre_fiscal))])]),(_vm.item.cargo)?_c('strong',[_vm._v(_vm._s(_vm.item.cargo))]):_vm._e()],1)]),_c('b-col',{staticClass:"mt-3"},[_c('h4',[_c('strong',[_vm._v("Información:")])]),_c('p',[_c('strong',[_vm._v("Dirección: ")]),_vm._v(_vm._s(_vm.item.direccion))]),_c('p',[_c('strong',[_vm._v("Teléfono: ")]),_vm._v(_vm._s(_vm.item.telefono))]),_c('p',[_c('strong',[_vm._v("Fax: ")]),_vm._v(_vm._s(_vm.item.fax))]),_c('p',[_c('strong',[_vm._v("Ubicación: ")]),_vm._v(_vm._s(_vm.item.ubicacion))]),_c('a',{staticClass:"btn btn-fiscalia btn-sm",style:({
                '--background-color-btn':
                  _vm.config.disenio.datos.color_secundario,
              }),attrs:{"href":'https://www.google.com/maps/dir/' +
                _vm.item.latitud +
                ',' +
                _vm.item.longitud,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Como llegar")])]),(_vm.item.descripcion)?_c('b-col',{staticClass:"mt-4",attrs:{"cols":"12"}},[_c('h4',[_c('strong',[_vm._v("Descripcion:")])]),(_vm.item.descripcion)?_c('div',{staticClass:"format-content",style:({
                '--background-color-primary':
                  _vm.config.disenio.datos.color_primario,

                '--background-color-tertiary':
                  _vm.config.disenio.datos.color_terciario,

                '--color-secundary': _vm.config.disenio.datos.color_secundario,
              }),domProps:{"innerHTML":_vm._s(_vm.item.descripcion)}}):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"mt-4"},[_c('l-map',{staticStyle:{"height":"500px","width":"100%"},attrs:{"zoom":_vm.zoom,"options":_vm.mapOptions,"center":_vm.center},on:{"update:zoom":function($event){_vm.zoom=$event}}},[_c('l-control-fullscreen',{attrs:{"position":"topleft","options":{
                title: {
                  false: 'Pantalla Completa!',
                  true: 'Pantalla Normal',
                },
              }}}),_c('l-tile-layer',{attrs:{"url":_vm.url,"attribution":_vm.attribution}}),_c('v-marker-cluster',{attrs:{"options":_vm.clusterOptions},on:{"clusterclick":function($event){return _vm.click()},"ready":_vm.ready}},_vm._l((_vm.locations),function(l){return _c('l-marker',{key:l.id,attrs:{"lat-lng":l.latlng,"icon":l.icon}},[_c('l-popup',{attrs:{"content":l.text}})],1)}),1)],1)],1)],2)],1),_c('div',{staticClass:"mt-5 mb-5 row",attrs:{"id":"navegacion"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }