<template>
  <div>
    <titulo-seccion>
      <template slot="area-rutas">
        <a href="/">Inicio</a> / <span class="active">Fiscalías</span>
      </template>
      <template slot="area-titulo">
        <h3 class="text-center" v-if="item.fiscalia_padre">
          Asiento Fiscal {{ formTitle }}
        </h3>
        <h3 class="text-center" v-else>{{ formTitle }}</h3>
      </template>
    </titulo-seccion>

    <div id="content-page" class="container mt-4">
      <b-row cols="12">
        <b-col lg="4">
          <b-card no-body class="card-developer-meetup">
            <div class="text-center bg-light-primary rounded-top">
              <b-img
                v-if="item.foto_fiscalia"
                :src="item.foto_fiscalia"
                :alt="item.nombre"
                class="img-fluid"
              />
              <b-img
                v-else
                :src="require('@/assets/images/imagen-mapa.png')"
                alt="Mapa ubicaciones"
                height="170"
              />
            </div>
            <b-card-body>
              <!-- metting header -->
              <div class="meetup-header d-flex align-items-center">
                <b-row>
                  <div class="my-auto">
                    <b-card-title class="mb-25">
                      <h3 v-if="item.fiscalia_padre">
                        <router-link
                          :to="'/fiscalia/' + item.fiscalia_padre.url"
                          >{{ item.fiscalia_padre.nombre }}</router-link
                        >
                      </h3>
                      <h3 v-else>
                        {{ item.nombre }}
                      </h3>
                    </b-card-title>
                    <b-card-text class="mb-0">
                      <b-button
                        class="btn-fiscalia"
                        :style="{
                          '--background-color-btn':
                            config.disenio.datos.color_secundario,
                        }"
                        block
                        to="/fiscalias"
                      >
                        Fiscalías</b-button
                      >
                    </b-card-text>
                  </div>
                </b-row>
              </div>
              <!--/ metting header -->

              <!-- media -->
            </b-card-body>
          </b-card>

          <b-list-group>
            <template v-for="item in itemsmenu">
              <b-list-group-item :key="item.id">
                <router-link
                  :to="'/fiscalia/' + item.url"
                  class="text-secondary"
                  :style="{
                    '--color-secondary': config.disenio.datos.color_secundario,
                  }"
                  style="text-decoration: none"
                  exact-path
                  >{{ item.nombre }}</router-link
                >
              </b-list-group-item>
            </template>
          </b-list-group>
        </b-col>

        <b-col lg="8">
          <div class="mt-2">
            <div class="input-group">
              <input
                class="form-control"
                v-model="textBusqueda"
                type="search"
                @keyup.enter="buscar"
                placeholder="Búsqueda por fiscalías"
              />
              <button
                class="btn btn-fiscalia"
                :style="{
                  '--background-color-btn':
                    config.disenio.datos.color_secundario,
                }"
                type="button"
                @click="buscar"
              >
                Buscar
              </button>
            </div>
          </div>
          <b-row class="mt-2" v-for="item in itemsBusqueda" :key="item.id">
            <b-col>
              <FiscaliaInfo :fiscaliaData="item" />
            </b-col>
          </b-row>
          <b-row>
            <b-col :cols="getCols(item.foto_fiscal, 4)" class="mt-3">
              <div class="text-center">
                <b-img-lazy
                  :src="item.foto_fiscal"
                  height="250"
                  width="250"
                  thumbnail
                  class="mb-1 rounded me-2 mb-2"
                  v-if="item.foto_fiscal"
                />
                <b-card-title>
                  <strong
                    class="text-secondary"
                    :style="{
                      '--color-secondary':
                        config.disenio.datos.color_secundario,
                    }"
                    >{{ item.nombre_fiscal }}</strong
                  >
                </b-card-title>
                <strong v-if="item.cargo">{{ item.cargo }}</strong>
              </div>
            </b-col>
            <b-col class="mt-3">
              <h4>
                <strong>Información:</strong>
              </h4>

              <p><strong>Dirección: </strong>{{ item.direccion }}</p>
              <p><strong>Teléfono: </strong>{{ item.telefono }}</p>
              <p><strong>Fax: </strong>{{ item.fax }}</p>
              <p><strong>Ubicación: </strong>{{ item.ubicacion }}</p>
              <a
                :href="
                  'https://www.google.com/maps/dir/' +
                  item.latitud +
                  ',' +
                  item.longitud
                "
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-fiscalia btn-sm"
                :style="{
                  '--background-color-btn':
                    config.disenio.datos.color_secundario,
                }"
                >Como llegar</a
              >
            </b-col>
            <b-col cols="12" v-if="item.descripcion" class="mt-4">
              <h4>
                <strong>Descripcion:</strong>
              </h4>
              <div
                v-if="item.descripcion"
                v-html="item.descripcion"
                class="format-content"
                :style="{
                  '--background-color-primary':
                    config.disenio.datos.color_primario,

                  '--background-color-tertiary':
                    config.disenio.datos.color_terciario,

                  '--color-secundary': config.disenio.datos.color_secundario,
                }"
              ></div>
            </b-col>
          </b-row>

          <div class="mt-4">
            <l-map
              :zoom.sync="zoom"
              :options="mapOptions"
              :center="center"
              style="height: 500px; width: 100%"
            >
              <l-control-fullscreen
                position="topleft"
                :options="{
                  title: {
                    false: 'Pantalla Completa!',
                    true: 'Pantalla Normal',
                  },
                }"
              />
              <l-tile-layer :url="url" :attribution="attribution" />
              <v-marker-cluster
                :options="clusterOptions"
                @clusterclick="click()"
                @ready="ready"
              >
                <l-marker
                  v-for="l in locations"
                  :key="l.id"
                  :lat-lng="l.latlng"
                  :icon="l.icon"
                >
                  <l-popup :content="l.text"></l-popup>
                </l-marker>
              </v-marker-cluster>
            </l-map>
          </div>
        </b-col>
      </b-row>
      <div id="navegacion" class="mt-5 mb-5 row"></div>
    </div>
  </div>
</template>

<script>
import axiosIns from "@/libs/axios";

import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import LControlFullscreen from "vue2-leaflet-fullscreen";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import TituloSeccion from "../secciones/TituloSeccion.vue";
import FiscaliaInfo from "../fiscalias/FiscaliaInfo.vue";
import {
  BListGroup,
  BListGroupItem,
  BCol,
  BRow,
  BButton,
  BCard,
  BCardBody,
} from "bootstrap-vue";
import { latLng, Icon, icon } from "leaflet";
import "leaflet/dist/leaflet.css";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    BCard,
    BCardBody,
    FiscaliaInfo,
    TituloSeccion,
    BButton,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LControlFullscreen,
    "v-marker-cluster": Vue2LeafletMarkerCluster,
  },
  data() {
    return {
      textBusqueda: "",
      locations: [],
      item: "",
      itemp: "",
      itemsmenu: [],
      itemsBusqueda: [],
      ubicacion: {
        position: { lat: -19.062117883514652, lng: -65.25878906250001 },
        visible: true,
        draggable: false,
      },
      selected: "0",
      zoom: 12,
      center: [-19.062117883514652, -65.25878906250001],
      clusterOptions: {},
      icon: icon({
        iconUrl: require("../../assets/images/leaflet/fiscalia-puntero.svg"),
        iconSize: [50, 50],
        iconAnchor: [16, 37],
      }),
      iconActivo: icon({
        iconUrl: require("../../assets/images/leaflet/fiscalia-puntero-activo.svg"),
        iconSize: [50, 50],
        iconAnchor: [16, 37],
      }),
      mapOptions: {
        zoomControl: true,
        attributionControl: true,
        zoomSnap: true,
      },
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    };
  },
  metaInfo() {
    return {
      title: "Ministerio Público",
      htmlAttrs: {
        lang: "es-ES",
      },
      titleTemplate: "%s | " + this.formTitle,
      meta: [
        { vmid: "description", name: "description", content: this.formTitle },
      ],
    };
  },
  mounted() {
    if (this.$route.params.slug) {
      this.obtenerFiscalia(this.$route.params.slug);
      setTimeout(() => {
        // console.log("done");
        this.$nextTick(() => {
          this.clusterOptions = { disableClusteringAtZoom: 11 };
        });
      }, 5000);
    }
  },
  updated() {
    // console.log("update");
  },
  computed: {
    formTitle() {
      return this.item.nombre ? this.item.nombre : this.item.nombre;
    },
    config() {
      return this.$store.state.config;
    },
  },
  created() {},
  watch: {
    $route: function (val) {
      if (val.params.slug) {
        this.obtenerFiscalia(this.$route.params.slug);
      }
    },
  },
  methods: {
    click: (e) => console.log("clusterclick", e),
    ready: (e) => console.log("ready", e),
    buscar() {
      if (this.textBusqueda != "") {
        const variable = `?busqueda=${this.textBusqueda}`;
        console.warn(variable);
        axiosIns
          .get(`/front/fiscalias${variable}`)
          .then((res) => {
            this.itemsBusqueda = res.data.data;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.itemsBusqueda = [];
      }
    },
    obtenerFiscalia(slug) {
      //var url = "/front/fiscalias";
      this.itemsmenu = [];
      this.locations = [];

      axiosIns
        .get(`/front/fiscalias/${slug}`)
        .then((res) => {
          if (res.data.data) {
            this.item = res.data.data;

            this.agregarPosicion(this.item, this.iconActivo);
            this.center = [this.item.latitud, this.item.longitud];
            if (this.item.fiscalias_hijas.length > 0) {
              this.agregarPosiciones(this.item.fiscalias_hijas);
              this.itemsmenu = this.item.fiscalias_hijas;
            } else {
              this.itemsmenu = [];

              axiosIns
                .get(`/front/fiscalias/${this.item.fiscalia_padre.url}`)
                .then((res) => {
                  this.itemp = res.data.data;
                  //this.agregarPosiciones(itemp.fiscalias_hijas);
                  this.itemsmenu = this.itemp.fiscalias_hijas;
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          } else {
            this.$router.push({ name: "error-404" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    agregarPosiciones(positionArray) {
      positionArray.forEach((element) => {
        this.agregarPosicion(element, this.icon);
      });
    },
    agregarPosicion(element, icon) {
      this.locations.push({
        id: element.id,
        latlng: latLng(element.latitud, element.longitud),
        icon: icon,
        text: `<div>                                      
                <table class="cuadro_info_dentro_mapa">
                    <thead>
                        <tr >
                            <th colspan="2" class="text-center">
                            <h5 class="text-black">${element.nombre}</h5>
                            </th>
                        </tr>
                    </thead>
                    
                    <tbody>                                            
                    <tr>
                        <th scope="row">Fiscal</th>
                        <td>${element.nombre_fiscal}</td>
                    </tr>
                    <tr>
                        <th scope="row">Dirección</th>
                        <td>${element.direccion}</td>
                    </tr>
                    <tr>
                        <th scope="row">Teléfono</th>
                        <td>${element.telefono}</td>
                    </tr>
                    <tr>
                        <th scope="row">Fax</th>
                        <td>${element.fax}</td>
                    </tr>
                    <tr>
                        
                        <td colspan="2"><a href="https://www.google.com/maps/dir/+${element.latitud},${element.longitud}" target="_blank" rel="noopener noreferrer" class="btn small">Como llegar</a></td>
                    </tr>
                    </tbody>
                </table>
                </div>`,
      });
    },
    getCols(fotoFiscal, cols) {
      if (window.innerWidth > 768 && fotoFiscal) {
        return fotoFiscal ? cols : "12";
      } else {
        return "12";
      }
    },
  },
};
</script>

<style>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

.format-content .table {
  width: 100% !important;
  /* background-color: red; */
}

.format-content .table table {
  width: 100% !important;
}

.format-content .table tbody tr:first-child td:first-child {
  background-color: var(--background-color-primary) !important;
  border: 1px solid #000000 !important;
  padding: 0.3cm 0.4pt 1pt !important;
}

.format-content .table tbody tr:first-child td:first-child p {
  color: #ffffff !important;
  margin: 0;
  padding: 0;
}

.format-content .table tbody tr td {
  text-align: center !important;
  padding-top: 1rem !important;
  vertical-align: middle !important;
}

.format-content .table tbody tr:nth-child(2) {
  background-color: var(--background-color-tertiary) !important;
  color: var(--color-secundary) !important;
  border: 1px solid #000000 !important;
}
.format-content .table tbody tr td:nth-child(1) {
  background-color: var(--background-color-tertiary) !important;
  color: var(--color-secundary) !important;
  border: 1px solid #000000 !important;
}
</style>
