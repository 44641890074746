var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',[_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-between flex-column",attrs:{"cols":"21","xl":"6"}},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('b-avatar',{staticClass:"mb-2",attrs:{"src":_vm.fiscaliaData.foto_fiscal,"text":_vm.fiscaliaData.nombre_fiscal,"size":"104px","rounded":""}}),_c('div',{staticClass:"ms-1 d-flex flex-column p-2"},[_c('div',{staticClass:"mb-1"},[_c('h4',{staticClass:"mb-0 fs-5"},[_vm._v(" "+_vm._s(_vm.fiscaliaData.nombre_fiscal)+" ")]),_c('span',{staticClass:"card-text fs-6"},[_vm._v(_vm._s(_vm.fiscaliaData.nombre))])]),_c('div',{staticClass:"flex-wrap d-flex"},[_c('b-button',{staticClass:"btn-fiscalia",style:({
                '--background-color-btn':
                  _vm.config.disenio.datos.color_secundario,
              }),attrs:{"to":'/fiscalia/' + _vm.fiscaliaData.url}},[_vm._v(" Visitar ")])],1)])],1)]),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('table',{staticClass:"mt-2 mt-xl-0 w-100"},[_c('tr',[_c('th',{staticClass:"pb-50"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Ubicación")])]),_c('td',{staticClass:"pb-50 text-capitalize"},[_vm._v(" "+_vm._s(_vm.fiscaliaData.ubicacion)+" ")])]),_c('tr',[_c('th',{staticClass:"pb-50"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Dirección")])]),_c('td',{staticClass:"pb-50 text-capitalize"},[_vm._v(" "+_vm._s(_vm.fiscaliaData.direccion)+" ")])]),_c('tr',[_c('th',{staticClass:"pb-50"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Teléfono")])]),_c('td',{staticClass:"pb-50"},[_vm._v(" "+_vm._s(_vm.fiscaliaData.telefono)+" ")])]),_c('tr',[_c('th',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Fax")])]),_c('td',[_vm._v(" "+_vm._s(_vm.fiscaliaData.fax)+" ")])]),_c('tr',[_c('td',[_c('a',{staticClass:"btn btn-fiscalia",style:({
                '--background-color-btn':
                  _vm.config.disenio.datos.color_secundario,
              }),attrs:{"href":'https://www.google.com/maps/dir//' +
                _vm.fiscaliaData.latitud +
                ',' +
                _vm.fiscaliaData.longitud,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Como llegar")])])])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }